/** @format */

import InventoryModule from '@/store/model/InventoryModule'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import PaginateOptions from '@/models/interface/PaginateOptions'
import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import { clone as _clone } from 'lodash'
import AdunitScheduler from './AdunitScheduler'
import ColorManager from './interface/ColorManager'

export default class Inventory {
  public id: string = ''

  public admanager_id: string = ''

  public name: string = ''

  public code: string = ''

  public ctv: boolean = false

  public desktop: boolean = false

  public mobile: boolean = false

  public live: boolean = false

  public length: string | null = null

  public group: string = ''

  public publisher: string = ''

  public publisher_id: string = ''

  public parent_id: string | null = null

  public status: boolean = false

  public created_at: string = ''

  public updated_at: string = ''

  public hierarchy_ids: string[] = []

  public disable_schedulers: boolean = false

  public cost: number = 0

  public get devices(): string[] {
    const ret: string[] = []

    if (this.ctv) ret.push('ctv')
    if (this.desktop) ret.push('desktop')
    if (this.mobile) ret.push('mobile')

    return ret
  }

  public scheduler_ids: string[] = []

  public get schedulers(): Array<AdunitScheduler> {
    return AdunitScheduler.filter(this.scheduler_ids)
  }

  public set schedulers(schedulers: Array<AdunitScheduler>) {
    this.scheduler_ids = schedulers.map(scheduler => scheduler.id)
    AdunitScheduler.toObjectList(schedulers)
  }

  public get hierarchy(): Array<Inventory> {
    if (!this.hierarchy_ids) return []
    return Inventory.filter(this.hierarchy_ids)
  }

  public get parent(): Inventory | null {
    if (!this.parent_id) return null
    const parent = Inventory.module.data.find(i => i.id === this.parent_id)

    if (parent instanceof Inventory) {
      return parent
    }
    return null
  }

  public set hierarchy(hierarchy: Array<Inventory>) {
    Inventory.toObjectList(hierarchy)
  }

  public get allSchedulers(): AdunitScheduler[] {
    if (this.parent_id && this.parent) {
      return [...this.schedulers, ...this.parent.allSchedulers.filter(s => s.apply_to_children)]
    }
    return [...this.schedulers]
  }

  public get active_schedulers(): AdunitScheduler[] {
    return this.disable_schedulers
      ? []
      : this.allSchedulers.filter(
        s => s.status === 'active' && !s.exclude_adunit_ids.includes(this.id),
      )
  }

  public get has_schedulers(): boolean {
    return this.allSchedulers.length > 0
  }

  public get events() {
    if (this.allSchedulers.length === 0) return []

    const events: Array<any> = []
    let id = 0

    // Add global scheduler events
    const global_scheduler = AdunitScheduler.module.data.find(s => s.is_global)
    if (
      !this.disable_schedulers
      && this.active_schedulers.length > 0
      && global_scheduler
      && global_scheduler.status === 'active'
    ) {
      global_scheduler.events.forEach((source_event: any) => {
        const event = _clone(source_event)
        id++
        let color: string = ''
        if (id <= ColorManager.colors.length) {
          color = ColorManager.colors[id - 1]
        } else {
          let idx = id
          let amount = 0
          do {
            idx -= ColorManager.colors.length
            amount++
          } while (idx > ColorManager.colors.length)
          color = ColorManager.LightenDarkenColor(ColorManager.colors[idx - 1], amount * 25)
        }
        event._color = color
        event.full_id = `${global_scheduler.id}:${event.id}`
        event.name = `[GLOBAL SHCEDULER] ${event.name}`
        events.push(event)
      })
    }

    this.active_schedulers.forEach(scheduler => {
      scheduler.events.forEach((source_event: any) => {
        const event = _clone(source_event)
        id++
        let color: string = ''
        if (id <= ColorManager.colors.length) {
          color = ColorManager.colors[id - 1]
        } else {
          let idx = id
          let amount = 0
          do {
            idx -= ColorManager.colors.length
            amount++
          } while (idx > ColorManager.colors.length)
          color = ColorManager.LightenDarkenColor(ColorManager.colors[idx - 1], amount * 25)
        }
        event._color = color
        event.full_id = `${scheduler.id}:${event.id}`
        event.name = `[${scheduler.name}] ${event.name}`
        events.push(event)
      })
    })

    return events
  }

  public get calendarEvents() {
    return AdunitScheduler.buildCalendarEvents(this.events)
  }

  public static toObject(data: any, cache: boolean = true): Inventory {
    const inventory = new Inventory()

    inventory.id = data.id
    inventory.admanager_id = data.admanager_id
    inventory.name = data.name
    inventory.ctv = data.ctv
    inventory.desktop = data.desktop
    inventory.mobile = data.mobile
    inventory.live = data.live
    inventory.length = data.length
    inventory.group = data.group
    inventory.publisher_id = data.publisher_id
    inventory.parent_id = data.parent_id
    inventory.status = data.status
    inventory.created_at = data.created_at
    inventory.updated_at = data.updated_at
    inventory.hierarchy_ids = data.hierarchy_ids
    inventory.code = data.code
    inventory.disable_schedulers = data.disable_schedulers

    if (data.schedulers) inventory.schedulers = data.schedulers
    if (data.hierarchy) inventory.hierarchy = data.hierarchy

    //  Cache Object List
    if (cache) Inventory.module.update(inventory)

    return inventory
  }

  public static toObjectList(data: any, cache: boolean = true): Inventory[] {
    const inventories = new Array<Inventory>()
    data.forEach((value: any) => {
      const inventory = Inventory.toObject(value, false)
      inventories.push(inventory)
    })

    //  Cache Object List
    if (cache) Inventory.module.update(inventories)

    return inventories
  }

  // State Management
  public static get module(): InventoryModule {
    if (!store.hasModule('inventory')) {
      store.registerModule('inventory', InventoryModule)
    }

    const m = getModule(InventoryModule)

    return m
  }

  public static async paginate(options: PaginateOptions) {
    return Inventory.module.paginate(options)
  }

  public static async get(id: string): Promise<Inventory | null> {
    return Inventory.module.find(id)
  }

  public getHistory(options: PaginateOptions) {
    const api = new Api()
    return api.get(`inventory/${this.id}/history`, options)
  }

  public deactivate() {
    this.status = false
    return this.save((response: any) => {
      WebMessage.success(`AdUnit "${this.name}" deactivated!`)
      return response
    })
  }

  public activate() {
    this.status = true
    return this.save((response: any) => {
      WebMessage.success(`AdUnit "${this.name}" activated!`)

      return response
    })
  }

  public disableSchedulers() {
    this.disable_schedulers = true
    return this.save((response: any) => {
      WebMessage.success(`AdUnit "${this.name}" shcedulers disabled!`)
      return response
    })
  }

  public enableSchedulers() {
    this.disable_schedulers = false
    return this.save((response: any) => {
      WebMessage.success(`AdUnit "${this.name}" shcedulers enabled!`)

      return response
    })
  }

  public save(callback: any = null) {
    const api = new Api()

    const data = {
      id: this.id,
      ctv: this.ctv,
      mobile: this.mobile,
      desktop: this.desktop,
      live: this.live,
      length: this.length,
      group: this.group,
      publisher: this.publisher,
      publisher_id: this.publisher_id,
      status: this.status,
      disable_schedulers: this.disable_schedulers,
    }

    if (this.id) {
      return api
        .put(`inventory/${this.id}`, data)
        .then(callback ?? this.onSave)
        .catch(this.onError)
    }
    return api
      .post('inventory', data)
      .then(callback ?? this.onSave)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const inventory = Inventory.toObject(response.data.result.inventory)
    WebMessage.success(`Inventory "${inventory.name}" saved!`)

    return response
  }

  private onError(error: any) {
    return error
  }

  public static buildTree(inventories: Array<Inventory>, parent_id: string | null = null): any {
    return inventories
      .filter(inventory => {
        if (parent_id === null) {
          return (
            inventory.parent_id === null
            || inventory.parent_id === '9237e7f6-f42b-481f-88c9-f0f66ae13506'
          )
        }
        return inventory.parent_id === parent_id
      })
      .map(inventory => ({
        text: inventory.name,
        id: inventory.id,
        children: Inventory.buildTree(inventories, inventory.id),
      }))
  }

  public static getTree(
    inventory: string | null = null,
    parent_id: string | null = null,
    query: string | null = null,
  ): any {
    const api = new Api()

    return api.get('inventories/tree', {
      inventory,
      parent_id,
      query,
    })
  }

  public static filter(filter: string[]): Inventory[] {
    if (Array.isArray(filter)) {
      return Inventory.module.data.filter(
        inventory => inventory.id && filter.includes(inventory.id),
      )
    }
    return Inventory.module.data.filter(filter)
  }
}
