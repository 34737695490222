import moment from 'moment'
import Vue from 'vue'
import AdunitSchedulerModule from '@/store/model/AdunitSchedulerModule'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import Api from './Api'
import WebMessage from './WebMessage'
import Schedule from './interface/Schedule'
import PaginateOptions from './interface/PaginateOptions'
import Inventory from './Inventory'
import LiveEvent from './LiveEvent'

export default class AdunitScheduler {
  public id: string = '';

  public name: string = '';

  public adunit_ids: string[] = [];

  public exclude_adunit_ids: string[] = [];

  public apply_to_children: boolean = false;

  public is_global: boolean = false;

  public events: Array<Schedule> = [];

  public status: string = 'active';

  public activated_at: string = '';

  public created_at: string = '';

  public updated_at: string = '';

  public get adunits(): Array<Inventory> {
    return Inventory.filter(this.adunit_ids)
  }

  public set adunits(adunits: Array<Inventory>) {
    Inventory.toObjectList(adunits)
  }

  public static buildCalendarEvents(events: Schedule[]) {
    const ret: Array<any> = []
    events.forEach(e => {
      if (e.type === 'once') {
        ret.push({
          id: e.id,
          type: e.type,
          date: e.start_at,
          end: e.end_at,
          title: e.name,
          backgroundColor: e.color,
          borderColor: e.color,
        })
      } else if (['weekly', 'monthly'].includes(e.type)) {
        ret.push({
          id: e.id,
          type: e.type,
          title: e.name,
          duration: { minutes: e.duration },
          rrule: e.rrule,
          backgroundColor: e.color,
          borderColor: e.color,
        })
      } else if (e.type === 'automated') {
        e.automated_events.forEach(ae => {
          const start = moment(ae.created_at)
          const end = moment(ae.created_at)
          if (e.automated_mode === 'all_day') {
            start.startOf('day')
            end.endOf('day')
          } else {
            end.add(e.game_duration, 'minutes')
          }
          ret.push({
            id: `${e.id}-${ae.id}`,
            type: e.type,
            title: ae.name,
            date: start.format('YYYY-MM-DD HH:mm:ss'),
            end: end.format('YYYY-MM-DD HH:mm:ss'),
            backgroundColor: e.color,
            borderColor: e.color,
            editable: false,
          })
        })
      }
    })
    return ret
  }

  public get calendarEvents() {
    return AdunitScheduler.buildCalendarEvents(this.events)
  }

  public validateEvent(event: Schedule): string | boolean {
    if (event.type === 'automated') {
      if (
        this.events.some(
          e => e.type === 'automated'
            && e.league_id === event.league_id
            && e.id !== event.id,
        )
      ) {
        return 'You already have an event with the same League Code.'
      }
    }

    return true
  }

  public addEvent(event: Schedule) {
    event.id = this.events.length + 1
    this.events.push(event)
  }

  public updateEvent(event: Schedule) {
    Vue.set(
      this,
      'events',
      this.events.map(e => {
        if (e.id === event.id) return event
        return e
      }),
    )
  }

  public removeEvent(id: number) {
    let idx = 1
    this.events = this.events
      .filter(e => e.id !== id)
      .map(e => {
        e.id = idx++
        return e
      })
  }

  public buildRequest(): any {
    return {
      name: this.name,
      adunit_ids: this.adunit_ids,
      exclude_adunit_ids: this.exclude_adunit_ids,
      apply_to_children: this.apply_to_children,
      is_global: this.is_global,
      events: this.events.map(e => e.buildRequest()),
      status: this.status,
    }
  }

  public deactivate() {
    this.status = 'inactive'
    return this.save((response: any) => {
      WebMessage.success(`Shceduler "${this.name}" deactivated!`)
      return response
    })
  }

  public activate() {
    this.status = 'active'
    return this.save((response: any) => {
      WebMessage.success(`Shceduler "${this.name}" activated!`)

      return response
    })
  }

  public disableAdunit(adunit: Inventory) {
    const api = new Api()
    return api
      .put(`adunit_scheduler/${this.id}/disable/${adunit.id}`)
      .then((response: any) => {
        WebMessage.success(
          `Scheduler ${this.name} disable for AdUnit "${adunit.name}" shcedulers enabled!`,
        )

        return response
      })
  }

  public enableAdunit(adunit: Inventory) {
    const api = new Api()
    return api
      .put(`adunit_scheduler/${this.id}/enable/${adunit.id}`)
      .then((response: any) => {
        WebMessage.success(
          `Scheduler ${this.name} enabled for AdUnit "${adunit.name}" shcedulers enabled!`,
        )

        return response
      })
  }

  public save(callback: any = null) {
    const api = new Api()

    if (this.id) {
      return api
        .put(`adunit_scheduler/${this.id}`, this.buildRequest())
        .then(callback ?? this.onSave)
        .catch(this.onError)
    }
    return api
      .post('adunit_scheduler', this.buildRequest())
      .then(callback ?? this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`adunit_scheduler/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const scheduler = AdunitScheduler.toObject(
      response.data.result.adunit_scheduler,
    )

    WebMessage.success(`Shceduler "${scheduler.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    WebMessage.success('Shceduler deleted!')

    return response
  }

  private onError(error: any) {
    return error
  }

  public static toObject(
    scheduler: any,
    cache: boolean = true,
  ): AdunitScheduler {
    const obj = new AdunitScheduler()

    obj.id = scheduler.id
    obj.name = scheduler.name
    obj.adunit_ids = scheduler.adunit_ids
    obj.exclude_adunit_ids = scheduler.exclude_adunit_ids
    obj.apply_to_children = scheduler.apply_to_children
    obj.is_global = scheduler.is_global
    obj.events = Schedule.toObjectList(scheduler.events)
    obj.status = scheduler.status
    obj.activated_at = scheduler.activated_at
    obj.created_at = scheduler.created_at
    obj.updated_at = scheduler.updated_at
    if (scheduler.adunits) obj.adunits = scheduler.adunits

    //  Cache Object List
    if (cache) AdunitScheduler.module.update(obj)

    return obj
  }

  public static toObjectList(
    schedulers: any[],
    cache: boolean = true,
  ): AdunitScheduler[] {
    const list = schedulers.map(s => AdunitScheduler.toObject(s, false))
    //  Cache Object List
    if (cache) AdunitScheduler.module.update(list)

    return list
  }

  /// State Management
  public static get module(): AdunitSchedulerModule {
    if (!store.hasModule('adunit_scheduler')) {
      store.registerModule('adunit_scheduler', AdunitSchedulerModule)
    }

    return getModule(AdunitSchedulerModule)
  }

  public static find(id: string): AdunitScheduler | null {
    const o = AdunitScheduler.module.data.find(
      scheduler => scheduler.id === id,
    )
    return o instanceof AdunitScheduler ? o : null
  }

  public static filter(filter: string[]): AdunitScheduler[] {
    if (Array.isArray(filter)) {
      return AdunitScheduler.module.data.filter(
        scheduler => scheduler.id && filter.includes(scheduler.id),
      )
    }
    return AdunitScheduler.module.data.filter(filter)
  }

  public static async get(id: string): Promise<AdunitScheduler | null> {
    return AdunitScheduler.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return AdunitScheduler.module.paginate(options)
  }
}
